<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <div>
          <sun-button
            variant="pill"
            class="px-4 text-xs bg-gray-700 custom-p-1 hover:bg-gray-800"
            color="gray"
            @click="goToNewCampaign"
          >
            + New Campaign
          </sun-button>
        </div>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick['name']" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.status`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              class="w-24"
              close-on-select
              disable-selected-options
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              :label="filter.textBy"
              :options="filter.options"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :track-by="filter.trackBy"
              :value="filtersMakeFiltersForSelect(value)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.type`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              class="w-24"
              close-on-select
              disable-selected-options
              :label="filter.textBy"
              :options="filter.options"
              :track-by="filter.trackBy"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.advertiser.id`]="{ filter, onSelect, value, isDuplicate }">
            <advertiser-filter
              :client-id="activeClient.id"
              :service="getAdvertisers"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              disable-selected-options
              @change="filtersOnSelectFilter(filter, $event, onSelect)"
            />
          </template>
          <template #[`pill.text.advertiser.id`]="{ filter }">
            {{ filter | showAdvertiser }}
          </template>
        </sun-filter-layout>

        <sun-data-table
          :class="{ loading: isLoading }"
          class="w-full"
          :content="items"
          :headers="headers"
          hoverable
          :loading="isLoading"
          @sort="onSortTable"
        >
          <template #[`col.advertiser`]="{ item }">
            <sun-data-table-cell>
              <advertiser-link :advertiser="item.advertiser" />
            </sun-data-table-cell>
          </template>

          <template #[`col.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.value" :color="item.status.color" />
            </sun-data-table-cell>
          </template>

          <template #[`col.type`]="{ item }">
            <sun-data-table-cell>
              {{ item.type.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.campaign.start`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip :date="item.startDate" class="inline-flex" />
            </sun-data-table-cell>
          </template>

          <template #[`col.campaign.end`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip :date="item.endDate" class="inline-flex" />
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item }">
            <sun-data-table-cell>
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { getCampaigns } from '@/services/modules/socialAudience/campaign';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { CONFIG } from './config';
import { getAdvertisers } from '@/services/modules/socialAudience/advertiser';
import AdvertiserFilter from '@/components/molecules/shared/filters/AdvertiserFilter';
import { create } from '@/router/private/modules/socialAudience/demand/campaign/create';
import { edit } from '@/router/private/modules/socialAudience/demand/campaign/edit';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { ROLES } from '@/model/shared/roles';
import FILTERS_CONFIG from '@/model/modules/socialAudience/filtersConfig.js';

export default {
  name: 'CampaignList',
  components: {
    AsterixSection,
    AdvertiserFilter,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    AdvertiserLink: () => import('@/components/atoms/AdvertiserLink'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  filters: {
    showAdvertiser: filter => {
      if (!filter) return ' - ';
      const platformName = filter?.meta?.platform?.name;
      const platformNameDisplay = platformName ? ` (${platformName})` : '';

      return `${filter.value}${platformNameDisplay}`;
    },
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { name: undefined },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    actions: [{ name: 'Edit' }],
    items: [],
    newCampaignRoute: create,
    editCampaignRoute: edit,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      activeContext: ACTIVE_CONTEXT,
    }),
    userRole() {
      return this.user.contextRoles.find(contextRole => contextRole.context === this.activeContext.id).role;
    },
  },
  async mounted() {
    if (this.userRole === ROLES.CLIENT.id) {
      this.filters = this.filters.filter(filter => filter !== FILTERS_CONFIG.ADVERTISER);
    }
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    onActionClick(event, item) {
      if (event.name === 'Edit') {
        this.goToEditCampaign(item.id);
      }
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getCampaigns);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    getAdvertisers(params) {
      return getAdvertisers(params, this.activeClient.id);
    },
    goToNewCampaign() {
      this.$router.push(this.newCampaignRoute);
    },
    goToEditCampaign(id) {
      this.$router.push({
        ...this.editCampaignRoute,
        params: { campaignId: id },
      });
    },
  },
};
</script>
