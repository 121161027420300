import FILTERS_CONFIG from '@/model/modules/socialAudience/filtersConfig.js';
import Column from '@/model/shared/Column';

export const CONFIG = {
  headers: [
    new Column('NAME', 'name').asSort().setClass('max-w-lg truncate font-bold'),
    new Column('ADVERTISER', 'advertiser'),
    new Column('STATUS', 'status').asSort(),
    new Column('TYPE', 'type').asSort(),
    new Column('INITIAL DATE', 'campaign.start'),
    new Column('END DATE', 'campaign.end'),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
  filters: [
    FILTERS_CONFIG.NAME,
    FILTERS_CONFIG.CAMPAIGN_STATUS,
    FILTERS_CONFIG.CAMPAIGN_TYPE,
    FILTERS_CONFIG.ADVERTISER,
  ],
};
